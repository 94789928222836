<template>
  <div>
    <div v-if="loading" class="loading-md bg">
      <i class="fas fa-spinner inner"></i>
      <br />
      <div v-if="!$helpers.isEmpty(loadingText)" class="loading-text">
        {{ loadingText }}
      </div>
    </div>
    <div v-else class="full">
      <div class="full">
        <div class="mainheader">
          <div class="half">
            {{ creatingNewEmployee ? "Neuer Mitarbeiter" : "Persönliche Daten" }}
          </div>
        </div>
      </div>
      <div class="half left">
        <div class="header">
          <div class="label">Grunddaten</div>
        </div>
        <div class="full">
          <div class="half left">
            <div class="label">Vorname</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('firstName'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.firstName"
                   v-bind:style="{
                      color: (savedEmployee.firstName != null && savedEmployee.firstName.length > 0) ? 'var(--contrast-4)' : '#f00',
                   }"
            />
          </div>
        </div>
        <div class="full">
          <div class="half left">
            <div class="label">Nachname</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('lastName'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.lastName"
                   v-bind:style="{
                      color: (savedEmployee.lastName != null && savedEmployee.lastName.length > 0) ? 'var(--contrast-4)' : '#f00',
                   }"
            />
          </div>
        </div>
        <div class="full">
          <div class="half left">
            <div class="label">Geburtsdatum</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('dob'),
            }"
          >
            <input :readonly="!editing" type="date" v-model="dateOfBirth"
                   v-bind:style="{
                      color: (dateOfBirth != null && dateOfBirth.length > 0) ? 'var(--contrast-4)' : '#f00',
                   }"
            />
          </div>
        </div>
        <div class="full split-bottom">
          <div class="half left">
            <div class="label">SV Nummer</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('socialsecurity'),
            }"
          >
            <input
                :readonly="!editing"
                type="text"
                v-model="savedEmployee.socialsecurity"
                v-bind:style="{
                    color: $parent.isSvnrValid(savedEmployee) ? 'var(--contrast-4)' : '#f00',
                }"
            />
          </div>
        </div>
        <div class="full split-bottom">
          <div class="half left">
            <div class="label">Kostenstelle <small>(Haupt Store)</small></div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('storeId'),
            }"
          >
            <dropdownsearch v-if="editing" style="width: 100%;" class="filters" :state="{
              input: storesArray.find((s) => s.id === savedEmployee.storeId) != undefined ? storesArray.find((s) => s.id === savedEmployee.storeId).name : 'bitte wählen',
              items: storesArray,
              onceSelected: function ($event, item, searchbar){
                savedEmployee.storeId = item.id;
                checkForPendingChanges();
              },
              selectedItem: storesArray.find((s) => s.id === savedEmployee.storeId),
              styleLight: true,
              style: '',
            }"/>
            <input v-else type="text" :readonly="true" v-model="displayStore"/>

          </div>
          <div class="half left">
            <div class="label">Externer Mitarbeiter</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('external'),
            }"
          >
            <checkbox :value="savedEmployee.external" @Update="savedEmployee.external = !savedEmployee.external" :disabled="!editing" />
          </div>
        </div>
        <div class="full split-bottom">
          <div class="half left">
            <div class="label">Beschäftigungsmodell</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('employmentModelId'),
            }"
          >
            <dropdownsearch v-if="editing" style="width: 100%;" class="filters" :state="{
              input: employmentModelsArray.find((s) => s.id === savedEmployee.employmentModelId) != undefined ? employmentModelsArray.find((s) => s.id === savedEmployee.employmentModelId).name : 'Nicht zugeordnet',
              items: employmentModelsArray,
              onceSelected: function ($event, item, searchbar){
                savedEmployee.employmentModelId = item.id;
                checkForPendingChanges();
              },
              selectedItem: employmentModelsArray.find((s) => s.id === savedEmployee.employmentModelId),
              styleLight: true,
              style: '',
            }"/>
            <input v-else type="text" :readonly="true" v-model="displayEmploymentModels"/>
          </div>

          <div class="half left">
            <div class="label">FIBU Kreis</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('financialAccountingGroupId'),
            }"
          >
            <dropdownsearch v-if="editing" style="width: 100%;" class="filters" :state="{
              input: financialAccountingGroupArray.find((s) => s.id === savedEmployee.financialAccountingGroupId) != undefined ?
                financialAccountingGroupArray.find((s) => s.id === savedEmployee.financialAccountingGroupId).name : 'Nicht zugeordnet',
              items: financialAccountingGroupArray,
              onceSelected: function ($event, item, searchbar){
                savedEmployee.financialAccountingGroupId = item.id;
                checkForPendingChanges();
              },
              selectedItem: financialAccountingGroupArray.find((s) => s.id === savedEmployee.financialAccountingGroupId),
              styleLight: true,
              style: '',
            }"/>
            <input v-else type="text" :readonly="true" v-model="displayFinancialAccountingGroup"/>
          </div>

          <div class="half left">
            <div class="label">Abrechnungskreis</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('allocationGroup'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.allocationGroup" />
          </div>

          <div class="half left">
            <div class="label">All-In Mitarbeiter</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('allIn'),
            }"
          >
            <checkbox :value="savedEmployee.allIn" @Update="savedEmployee.allIn = !savedEmployee.allIn" :disabled="!editing" />
          </div>

          <!--
                {{genderArray}}
                <div class="half cd-input" v-bind:class="{roinput: !editing, mandatory:mandatoryAndMissing('gender')}">
                    <dropdownsearch v-if="editing" style="width: 100%;" class="filters" :state="filtersCitizen"/>
                    <input v-else type="text" v-model="displayGender"/>
                </div>
                -->
        </div>
        <div class="full split-bottom">
          <div class="half left">
            <div class="label">Geschlecht</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('gender'),
            }"
          >
            <dropdownsearch v-if="editing" style="width: 100%;" class="filters" :state="{
              input: genderArray.find((s) => s.id === savedEmployee.genderId) != undefined ?
                genderArray.find((s) => s.id === savedEmployee.genderId).valueGER : 'Nicht angegeben',
              items: genderArray,
              onceSelected: function ($event, item, searchbar){
                savedEmployee.genderId = item.id;
                checkForPendingChanges();
              },
              selectedItem: genderArray.find((s) => s.id === savedEmployee.genderId),
              styleLight: true,
            }"/>
            <input v-else type="text" :readonly="true" v-model="displayGender"
                   v-bind:style="{
                      color: savedEmployee.genderId != 0 ? 'var(--contrast-4)' : '#f00',
                   }"
            />

          </div>
          <!--
                {{genderArray}}
                <div class="half cd-input" v-bind:class="{roinput: !editing, mandatory:mandatoryAndMissing('gender')}">
                    <dropdownsearch v-if="editing" style="width: 100%;" class="filters" :state="filtersCitizen"/>
                    <input v-else type="text" v-model="displayGender"/>
                </div>
                -->
        </div>
        <div class="full">
          <div class="half left">
            <div class="label">Adresse</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('address'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.address" />
          </div>
        </div>
        <div class="full">
          <div class="half left">
            <div class="label">PLZ</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('postalCode'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.postalCode" />
          </div>
        </div>
        <div class="full">
          <div class="half left">
            <div class="label">Stadt</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('city'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.city" />
          </div>
        </div>
        <div class="full split-bottom">
          <div class="half left">
            <div class="label">Land</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('country'),
            }"
          >
            <!-- lower z-index then selection above ensure above selection overlays this one -->

            <dropdownsearch v-if="editing" style="width: 100%;" class="filters" :state="{
              input: itemsCountry.find((s) => s.code === savedEmployee.countryId) != undefined ?
                itemsCountry.find((s) => s.code === savedEmployee.countryId).label : 'bitte wählen',
              items: itemsCountry,
              onceSelected: function ($event, item, searchbar){
                savedEmployee.countryId = item.code;
                checkForPendingChanges();
              },
              selectedItem: itemsCountry.find((s) => s.code === savedEmployee.countryId),
              styleLight: true,
              style: ''
            }"/>
            <input v-else type="text" :readonly="true" v-model="displayCountry"/>
          </div>
        </div>
        <div class="full split-bottom">
          <div class="half left">
            <div class="label">Nationalität</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('citizenship'),
            }"
          >
            <!-- lower z-index then selection above ensure above selection overlays this one -->


            <dropdownsearch v-if="editing" style="width: 100%;" class="filters" :state="{
              input: itemsCitizenship.find((s) => s.code === savedEmployee.citizenshipId) != undefined ?
                itemsCitizenship.find((s) => s.code === savedEmployee.citizenshipId).label : 'bitte wählen',
              items: itemsCitizenship,
              onceSelected: function ($event, item, searchbar){
                savedEmployee.citizenshipId = item.code;
                checkForPendingChanges();
              },
              selectedItem: itemsCitizenship.find((s) => s.code === savedEmployee.citizenshipId),
              styleLight: true,
              style: ''
            }"/>
            <input v-else type="text" :readonly="true" v-model="displayCitizenship"/>
          </div>
        </div>

        <div class="full split-bottom">
          <div class="half left">
            <div class="label">Bürgertyp</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('citizenTypeId'),
            }"
          >
            <dropdownsearch v-if="editing" style="width: 100%;" class="filters" :state="{
              input: itemsCitizenType.find((s) => s.id === savedEmployee.citizenTypeId) != undefined ?
                itemsCitizenType.find((s) => s.id === savedEmployee.citizenTypeId).valueGER : 'Nicht angegeben',
              items: itemsCitizenType,
              onceSelected: function ($event, item, searchbar){
                savedEmployee.citizenTypeId = item.id;
                checkForPendingChanges();
              },
              selectedItem: itemsCitizenType.find((s) => s.id === savedEmployee.citizenTypeId),
              styleLight: true,
              style: '',
            }"/>
            <input v-else type="text" :readonly="true" v-model="displayCitizenType"/>
          </div>
        </div>
        <div class="full split-bottom">
          <div class="half left">
            <div class="label">Familienstand</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('civilStatusId'),
            }"
          >

            <dropdownsearch v-if="editing" style="width: 100%;" class="filters" :state="{
              input: itemsCivilStatus.find((s) => s.id === savedEmployee.civilStatusId) != undefined ?
                itemsCivilStatus.find((s) => s.id === savedEmployee.civilStatusId).valueGER : 'Nicht angegeben',
              items: itemsCivilStatus,
              onceSelected: function ($event, item, searchbar){
                savedEmployee.civilStatusId = item.id;
                checkForPendingChanges();
              },
              selectedItem: itemsCivilStatus.find((s) => s.id === savedEmployee.civilStatusId),
              styleLight: true,
              style: '',
            }"/>
            <input v-else type="text" :readonly="true" v-model="displayCivilStatus"/>

          </div>
        </div>

        <div class="full" v-bind:class="{ 'split-bottom': !showBmdIdField }">
          <div class="half left">
            <div class="label">Personalnummer (Freitextfeld)</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('mdPersonnelId'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.mdPersonnelId" />
          </div>
        </div>
        <div v-if="showBmdIdField" class="full split-bottom">
          <div class="half left">
            <div class="label">BMD ID</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('bmdId'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.bmdId" />
          </div>
        </div>
      </div>
      <div class="half right no-float">
        <div class="full">
          <div class="half left">
            <div class="label">Email</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('email'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.email" />
          </div>
        </div>
        <div class="full">
          <div class="half left">
            <div class="label">Telefon</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('homephone'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.homephone" />
          </div>
        </div>
        <div class="full split-bottom">
          <div class="half left">
            <div class="label">Mobil</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('mobilephone'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.mobilephone" />
          </div>
        </div>
        <!--
            <div class="full">
                <div class="half left">
                    <div class="label">Phone</div>
                </div>
                <div class="half cd-input" v-bind:class="{roinput: !editing, mandatory:mandatoryAndMissing('phone')}">
                    <input :readonly="!editing" type="text" v-model="savedEmployee.phone"/>
                </div>
            </div>
            -->
        <div class="full">
          <div class="half left">
            <div class="label">Konto Inhaber</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('bankAccountHolder'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.bankAccountHolder" />
          </div>
        </div>
        <div class="full">
          <div class="half left">
            <div class="label">IBAN</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('iban'),
            }"
          >
            <input
              :readonly="!editing"
              type="text"
              v-model="savedEmployee.iban"
              v-bind:style="{
                color: $parent.isIbanValid(savedEmployee.iban) ? 'var(--contrast-4)' : '#f00',
              }"
            />
          </div>
        </div>
        <div class="full split-bottom">
          <div class="half left">
            <div class="label">BIC</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('bic'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.bic" />
          </div>
        </div>

        <div class="full split-bottom">
          <div class="half left">
            <div class="label">50% Behinderung</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('disability'),
            }"
          >
            <checkbox :value="savedEmployee.disability" @Update="savedEmployee.disability = !savedEmployee.disability" :disabled="!editing" />
          </div>
        </div>
        <div class="full">
          <div class="half left">
            <div class="label">Erziehungsberechtigter Name</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('guardianName'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.guardianName" />
          </div>
        </div>
        <div class="full">
          <div class="half left">
            <div class="label">Erziehungsberechtigter Adresse</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('guardianAddress'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.guardianAddress" />
          </div>
        </div>
        <div class="full split-bottom">
          <div class="half left">
            <div class="label">Erziehungsberechtigter Telefon</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('guardianPhone'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.guardianPhone" />
          </div>
        </div>
        <div class="full">
          <div class="half left">
            <div class="label">Führerschein</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('driverslicense'),
            }"
          >
            <checkbox :value="savedEmployee.driverslicense" @Update="savedEmployee.driverslicense = !savedEmployee.driverslicense" :disabled="!editing" />
          </div>
        </div>
        <div class="full split-bottom">
          <div class="half left">
            <div class="label">Führerschein Ablaufdatum</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('dlExpiry'),
            }"
          >
            <input :readonly="!editing" type="date" v-model="savedEmployee.dlExpiry" />
          </div>
        </div>
        <div class="full">
          <div class="half left">
            <div class="label">Erste Hilfe Kurs</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('firstaidcourse'),
            }"
          >
            <checkbox :value="savedEmployee.firstaidcourse" @Update="savedEmployee.firstaidcourse = !savedEmployee.firstaidcourse" :disabled="!editing" />
          </div>
        </div>
        <div class="full split-bottom">
          <div class="half left">
            <div class="label">Erste Hilfe Kurs Ablaufdatum</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('faExpiry'),
            }"
          >
            <input :readonly="!editing" type="date" v-model="savedEmployee.faExpiry" />
          </div>
        </div>
        <div class="full">
          <div class="half left">
            <div class="label">Sonstige Info</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('miscInfo'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.miscInfo" />
          </div>
        </div>

        <div class="full">
          <div class="half left">
            <div class="label">Vordienstzeit (Monate)</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('workExperience'),
            }"
          >
            <input :readonly="!editing" type="text" v-model="savedEmployee.workExperience" />
          </div>
        </div>

        <div class="full">
          <div class="half left">
            <div class="label">Ersteintrittsdatum</div>
          </div>
          <div
            class="half cd-input"
            v-bind:class="{
              roinput: !editing,
              mandatory: mandatoryAndMissing('workEntryDate'),
            }"
          >
            <input :readonly="!editing" type="date" v-model="savedEmployee.workEntryDate" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dropdownsearch from "../uicomponents/dropdownsearch.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    dropdownsearch,
  },
  name: "employeecoredata",
  data() {
    return {
      genderArray: [],
      storesArray: [],
      employmentModelsArray: [],
      financialAccountingGroupArray: [],

      itemsCitizenship: [],
      itemsCountry: [],
      itemsCitizenType: [],
      itemsCivilStatus: [],

      dataFiltered: [],
      loading: true,
      loadingText: "Daten werden aktualisiert...",
      dragWatch: -1,
      mouseCurrent: {
        x: 0,
        y: 0,
      },
      mouseDragStart: {
        x: 0,
        y: 0,
      },
      scrolling: false,
      scrollTop: 150,
      dropdowns: [],
      savedEmployee: {},
      pendingChanges: false,
      editing: false,
    };
  },
  watch: {
    savedEmployee: {
      deep: true,
      handler(val, old) {
        if (val.storeId == null || val.storeId == undefined) {
          val.storeId = null;
        }
        if (val.employmentModelId == null || val.employmentModelId == undefined) {
          val.employmentModelId = 0;
        }
        if (val.genderId == null || val.genderId == undefined) {
          val.genderId = 0;
        }
        if (val.countryId == null || val.countryId == undefined) {
          val.countryId = 0;
        }
        if (val.citizenshipId == null || val.citizenshipId == undefined) {
          val.citizenshipId = 0;
        }
        if (val.financialAccountingGroupId == null || val.financialAccountingGroupId == undefined) {
          val.financialAccountingGroupId = 0;
        }

        this.checkForPendingChanges();
      },
    },
    nameFilter: function (val, oldVal) {
      if (val === "") {
        this.dataFiltered = this.gridData.data.slice();
        return;
      }
      this.dataFiltered = this.gridData.data.filter((e) => (e.firstName.toLowerCase() + " " + e.lastName.toLowerCase()).indexOf(val.toLowerCase()) > -1);
    },
    employee: function (val, oldVal) {
      this.editing = this.creatingNewEmployee;
      this.savedEmployee = Object.assign({}, this.employee);
      if (this.creatingNewEmployee) {
        this.savedEmployee.financialAccountingGroupId = this.financialAccountingGroupArray.find((s) => s.isDefault === 1) ?
          this.financialAccountingGroupArray.find((s) => s.isDefault === 1).id : 0;
      }
    },
  },
  props: ["employee", "creatingNewEmployee", "companyData"],
  computed: {
      ...mapGetters([
        "storeMap",
        "countryEnumMap",
        "genderEnumMap",
        "citizentypeEnumMap",
        "civilstatusEnumMap",
      ]),
      mandatoryFields(){
        let mandatoryFields = ['firstName', 'lastName', 'email', 'dob' ];
        if(this.companyData && this.companyData.id == 116){
          mandatoryFields.push('mdPersonnelId')
        }
        return mandatoryFields;
      },
    dateOfBirth: {
      get: function () {
        var dateStr;
        if (this.savedEmployee.dob) {
          dateStr = this.$helpers.extractDateString(this.savedEmployee.dob);
        }
        return dateStr;
      },
      set: function (val) {
        this.savedEmployee.dob = this.$helpers.convertDateTimeStringUTC(val);
      },
    },
    displayGender: {
      get: function () {
        var str = "";
        if (this.savedEmployee.genderId != undefined) {
          if (this.savedEmployee.genderId == 0) {
            return "Nicht angegeben";
          } else if (this.savedEmployee.genderId == 1) {
            return "männlich";
          } else if (this.savedEmployee.genderId == 2) {
            return "weiblich";
          } else if (this.savedEmployee.genderId == 3) {
            return "divers";
          }
        }
        return str;
      },
    },
    displayCountry: {
      get: function () {
        var str = "Nicht angegeben";
        if (this.itemsCountry.find((s) => s.code === this.savedEmployee.countryId) != undefined) {
          str = this.itemsCountry.find((s) => s.code === this.savedEmployee.countryId).label;
        }
        return str;
      },
    },
    displayCitizenship: {
      get: function () {
        var str = "Nicht angegeben";
        if (this.itemsCitizenship.find((s) => s.code === this.savedEmployee.citizenshipId) != undefined) {
          str = this.itemsCitizenship.find((s) => s.code === this.savedEmployee.citizenshipId).label;
        }
        return str;
      },
    },
    displayStore: {
      get: function () {
        var str = "Nicht zugeordnet";
        if (this.storesArray.find((s) => s.id === this.savedEmployee.storeId) != undefined) {
          str = this.storesArray.find((s) => s.id === this.savedEmployee.storeId).name;
        }
        return str;
      },
    },
    displayEmploymentModels: {
      get: function () {
        var str = "Nicht zugeordnet";
        if (this.employmentModelsArray.find((s) => s.id === this.savedEmployee.employmentModelId) != undefined) {
          str = this.employmentModelsArray.find((s) => s.id === this.savedEmployee.employmentModelId).name;
        }
        return str;
      },
    },
    displayFinancialAccountingGroup: {
      get: function () {
        var str = "Nicht zugeordnet";
        if (this.financialAccountingGroupArray.find((s) => s.id === this.savedEmployee.financialAccountingGroupId) != undefined) {
          str = this.financialAccountingGroupArray.find((s) => s.id === this.savedEmployee.financialAccountingGroupId).name;
        }
        return str;
      },
    },
    displayCitizenType: {
      get: function () {
        var str = "Nicht angegeben";
        if (this.itemsCitizenType.find((s) => s.id === this.savedEmployee.citizenTypeId) != undefined) {
          str = this.itemsCitizenType.find((s) => s.id === this.savedEmployee.citizenTypeId).label;
        }
        return str;
      },
    },
    displayCivilStatus: {
      get: function () {
        var str = "Nicht angegeben";
        if (this.itemsCivilStatus.find((s) => s.id === this.savedEmployee.civilStatusId) != undefined) {
          str = this.itemsCivilStatus.find((s) => s.id === this.savedEmployee.civilStatusId).label;
        }
        return str;
      },
    },
    showBmdIdField() {
      let view = this;
      if (view.companyData != null && view.companyData.softwareId === 0
          && view.employee.bmdId !== -1 && String(view.employee.bmdId) !== String(view.employee.mdPersonnelId)) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    let view = this;
    console.log("Mounted EmployeeCoreData");

    this.loading = true;

    let promises = [];
    let url = "/sec/employmentModels";
    promises.push(this.api.get(url).then((response) => {
      view.employmentModelsArray.push({ id: 0, label: "Nicht zugeordnet", name: "Nicht zugeordnet" });
      for (let x in response.data.data) {
        let elem = response.data.data[x];
        elem.label = elem.name;
        view.employmentModelsArray.push(elem);
      }
    }));

    url = "/sec/financialAccountingGroup";
    promises.push(this.api.get(url).then((response) => {
      view.financialAccountingGroupArray.push({ id: 0, label: "Nicht zugeordnet", name: "Nicht zugeordnet" });
      for (let x in response.data.data) {
        let elem = response.data.data[x];
        elem.label = elem.name;
        view.financialAccountingGroupArray.push(elem);
      }
    }));

    this.savedEmployee = Object.assign({}, this.employee);
    this.editing = this.creatingNewEmployee;

    this.itemsCountry = [];
    this.itemsCitizenship  = [];
    Object.values(this.countryEnumMap).forEach(c => {
      this.itemsCountry.push({code: c.id, label: c.valueGER, name: c.valueGER});
    });
    this.itemsCountry.sort((a, b) => a.label.localeCompare(b.label));
    this.itemsCitizenship = JSON.parse(JSON.stringify(this.itemsCountry));

    this.genderArray = [];
    Object.values(this.genderEnumMap).forEach(c => {
      this.genderArray.push({id: c.id, code: c.id, value: c.id, label: c.valueGER, name: c.valueGER});
    });

    this.itemsCitizenType = [];
    Object.values(this.citizentypeEnumMap).forEach(c => {
      this.itemsCitizenType.push({id: c.id, code: c.id, value: c.key, label: c.valueGER, name: c.valueGER});
    });

    this.itemsCivilStatus = [];
    Object.values(this.civilstatusEnumMap).forEach(c => {
      this.itemsCivilStatus.push({id: c.id, code: c.id, value: c.key, label: c.valueGER, name: c.valueGER});
    });

    view.storesArray = [{ id: 0, label: "Nicht zugeordnet", name: "Nicht zugeordnet" }];
    Object.values(this.storeMap).forEach(s => {
      s.label = s.name;
      this.storesArray.push(s);
    });

    Promise.all(promises)
      .then(response => {
        view.$forceUpdate();
        console.log("loading done");
        this.loading = false;
      });
  },
  destroyed() {},
  methods: {
    toggleEditMode() {
      this.editing = !this.editing;
    },
    cancelEditMode() {
      this.savedEmployee = Object.assign({}, this.employee);
      this.editing = false;
      if (this.creatingNewEmployee) {
        this.$emit("employee-creation-cancelled");
      } else {
        this.$emit("employee-edit-cancelled");
      }
    },
    mandatoryAndMissing(key) {
      let specialGermanCompanies = [116, 136, 137];
      if (this.mandatoryFields.includes(key)) {
        if(this.companyData && specialGermanCompanies.includes(this.companyData.id) && key == 'personnelId'){
          let value = this.savedEmployee[key]
          let isMissing = this.$helpers.isEmpty(value);
          let isNum = true;
          if(!isMissing){
            isNum = /^\d+$/.test(value);
          }
          return isMissing || !isNum;
        } else {
          return this.$helpers.isEmpty(this.savedEmployee[key])
        }
        //return true;
      }
      return false;
    },
    checkForPendingChanges() {
      var keys = Object.keys(this.savedEmployee);
      var changedKeys = [];
      keys.forEach((key) => {
        if (key != "metadata") {
          // metadata is calculated employee state date added for frontend purpopses only
          if (!(key in this.employee) || this.savedEmployee[key] != this.employee[key]) {
            changedKeys.push(key);
          }
        }
      });
      this.pendingChanges = changedKeys.length > 0;
      return changedKeys;
    },
    itemWithId(id, arr) {
      for (const item of arr) {
        if (item.id == id) return item;
      }
      // fallback, should not happen
      return { id: "", name: "", label: "" };
    },
    Init(items, config) {},
    gender(code) {
      switch (code) {
        case "m":
        case "M":
          return "männlich";
        case "f":
        case "F":
          return "weiblich";
        default:
          return "menschlich";
      }
    },
    updateChangedFields(generateChangePdf = false) {
      this.loadingText = "Daten werden gespeichert...";
      var changedKeys = this.checkForPendingChanges();
      let payload = {};
      this.loading = true;
      changedKeys.forEach((key) => {
        let val = this.savedEmployee[key];
        switch (key) {
          case "dob":
            val = this.$helpers.extractDateString(val);
            break;
        }
        payload[key] = val;
      });
      console.log(payload);
      if (changedKeys.length > 0) {
        let view = this;
        var keys = [];
        changedKeys.forEach((key) => {
          if (key != "metadata") {
            // metadata is only calculated info added for frontend
            keys.push(key);
          }
        });
        if (keys.length > 0) {
          let url = "/sec/employee/coredata/" + this.savedEmployee.id;
          console.log(payload);
          this.api
            .put(url, payload)
            .then((response) => {
              console.log(response);
              if (response.status == 200) {
                view.$helpers.success("Gespeichert", "Änderungen gespeichert!");
                keys.forEach((key) => {
                  this.employee[key] = this.savedEmployee[key];
                });
                view.$emit("updated", view.employee.id, true);
                view.checkForPendingChanges();
                view.$forceUpdate();
              }
              this.loading = false;
              view.editing = false;
            })
            .catch(function (error) {
              view.loading = false;
              console.log(error);
              console.log(error.message);
              view.$helpers.error(error.message);
            });
        }
      }
    },
    createEmployee() {
      this.$helpers.log(this, "createEmployee", this.employee);
      this.getCandidates(this.savedEmployee).then((response) => {
        let candidatesResponse = response.data.data;
        console.log(candidatesResponse);
        let selectableItems = this.transformCandidateResult(candidatesResponse);
        if (selectableItems.length > 0) {
          this.openCandidateChooserPopup(this.savedEmployee, selectableItems).then((remoteId) => {
            if (remoteId != null) {
              // employee exists
              this.$emit("select-employee-id", remoteId);
            } else {
              this.saveEmployee(this.savedEmployee);
            }
          });
        } else {
          this.saveEmployee(this.savedEmployee);
        }
      });
      //this.$emit('create-employee', this.savedEmployee);
    },
    saveEmployee(employee) {
      let view = this;
      console.log("Ready to save!");
      console.log(employee);
      let payload = employee;
      payload.dob = this.$helpers.extractDateString(payload.dob);

      // TODO: main store selection
      let url = "/sec/employee/coredata";
      this.api.post(url, payload).then((response) => {
        console.log(response);
        if (response.status == 200) {
          view.$helpers.success("Gespeichert", "Mitarbeiter gespeichert!");
          view.toggleEditMode();
          view.creatingNewEmployee = false;
          view.$emit("create-employee", response.data);
          view.checkForPendingChanges();
          view.$forceUpdate();
        }
        view.editing = false;
      });
    },
    getCandidates(employee) {
      let url = "/sec/employee/proxyMDCoreCandidate";
      url = this.$helpers.appendParam("firstName", employee.firstName, url, "/sec/employee/proxyMDCoreCandidate");
      url = this.$helpers.appendParam("lastName", employee.lastName, url, "/sec/employee/proxyMDCoreCandidate");
      if (employee.dob != null) url = this.$helpers.appendParam("dob", this.$helpers.extractDateString(employee.dob), url, "/sec/employee/proxyMDCoreCandidate");
      if (employee.socialsecurity != null) url = this.$helpers.appendParam("socialsecurity", employee.socialsecurity, url, "/sec/employee/proxyMDCoreCandidate");
      console.log(url);
      return this.api.get(url);
    },
    transformCandidateResult(candidateResult) {
      let candidates = candidateResult.candidates;
      let matches = candidateResult.matches;
      let selectableItems = [];
      candidates.forEach((candidate) => {
        let bd = candidate.date_of_birth != null ? this.$helpers.getMomentFromStringTZ(candidate.date_of_birth).format("DD.MM.YYYY") : "";
        let item = {
          label: candidate.firstname + " " + candidate.lastname + " " + bd + " (Übereinstimmung: " + candidate.score + "%)",
          value: candidate.id,
          score: candidate.score,
        };
        selectableItems.push(item);
      });
      matches.forEach((candidate) => {
        let bd = candidate.date_of_birth != null ? this.$helpers.getMomentFromStringTZ(candidate.date_of_birth).format("DD.MM.YYYY") : "";
        let item = {
          label: candidate.firstname + " " + candidate.lastname + " " + bd + " 100%",
          value: candidate.id,
          score: 100,
        };
        selectableItems.push(item);
      });
      selectableItems.sort((a, b) => {
        return -(a.score - b.score);
      });
      return selectableItems;
    },
    openCandidateChooserPopup(newEmployee, selectableItems) {
      let view = this;
      let entries = [];

      selectableItems.unshift({
        label: "Mitarbeiter neu anlegen",
        value: null,
      });

      let description =
        "Es wurden Mitarbeiter in ihrer Firma mit ähnlichen Daten gefunden. <br/> Sie können den Mitarbeiter neu anlegen oder zu einem bereits bestehenden Mitarbeiter wechseln:";

      entries.push({
        label: "Bitte auswählen",
        key: "remoteId",
        hidden: false,
        type: "dropdownsearch",
        value: null,
        selectMultiple: false,
        dropdownSearchState: {
          toggled: false,
          displayedItems: [],
          input: "",
          items: selectableItems,
          selectMultiple: false,
          selectedItems: [],
          selectFirst: true,
          styleLight: true,
        },
      });

      return new Promise((resolve, accept) => {
        view.$helpers.OpenPopup({
          type: "datafill",
          componentState: {
            entries: entries,
            description: description,
            selectCallback: function (item) {
              resolve(item.remoteId);
            },
          },
          header: "Mitarbeiter anlegen: " + newEmployee.firstName + " " + newEmployee.lastName,
          centered: true,
          expanded: true,
        });
      });
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.dropdown-search.filters {
  height: 25px !important;
  margin-top: -4px;
  display: block;
}

.input-component {
  text-align: left;
}

.checkbox-component {
  margin-top: -5px;
}

/*
.content *:not(.main-settings)>.half {
    height: 105px;
}
*/
</style>
